import React from "react"
import { Link } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

//Google Maps Key I HATE YOU GOOGLE
//const MAPS_KEY = 'AIzaSyCK_MYBBh3VDsIsyMQWSodVi044-vj2_7g';
const MAPBOX_TOKEN = 'pk.eyJ1IjoibWlrZXdpbmVyIiwiYSI6ImNraXQxZnBvdzAzeG4ycW4yZXJvZGpxeHAifQ.B6QN3SNmp8jzZMZvZNsGtA'

const Card = ({ shop }) => {
  // console.log(shop)
  const storeHours = shop.description.substring(
    0,
    shop.description.indexOf(";")
  )
  const storeHoursArray = storeHours.split("\\n")
  return (
    <div className="card shadow-4 mb4 flex flex-column-reverse justify-end flex-row-l grow ">
      <div className="card-text w-100 w-50-l pa3">
        <div className='flex w-100 items-start justify-between pb2 ma0'>
          <h3 className="f6 dib black">{shop.name.toUpperCase()}</h3>
          {shop.isEnabled ? (
            <p className="status-tag f7 tc bg-green white br2 pa1 dib w3 fw7 lh-copy">
              ONLINE
            </p>
          ) : (
            <p className="status-tag f7 tc bg-red white br2 pa1 ma1 dib w3 fw7 lh-copy">
              OFFLINE
            </p>
          )}
        </div>
        <div className="mb3">
          <p className="">
            {shop.location.street + `, ` + shop.location.city + `,`}
          </p>
          <p className="">{shop.location.zip + `, ` + shop.location.country}</p>
        </div>
        {storeHoursArray.map((storeHour, index) => {
          return <p key={index}>{storeHour}</p>
        })}
        <p className="ba b--gold dt mv3 pa1 br2 bw1">
          {`1BTC: $` + parseFloat(shop.prices.BTC_BuyPrice).toFixed(2)}
        </p>

        <Link className="white f6 no-underline" to="/404">
          <button
            className="select-button mv3 bn grow pointer ph3 pv2 mb2 dib white bg-orange br2 "
            onClick={e => {
              trackCustomEvent({
                category: "Special Button",
                action: "Click",
                label: "clicked the secret button",
                value: 43,
              })
            }}
          >
            Select
          </button>
        </Link>
      </div>
      <div className="w-100 w-50-l">
        <img
          alt="google maps"
          className="google-maps-image w-100 h-100 "
          style={{ maxWidth: `100%`, maxHeight: `100%` }}
          // src={`https://maps.googleapis.com/maps/api/staticmap?center=${shop.location.latitude},${shop.location.longitude}&zoom=12&size=325x325&key=${MAPS_KEY}&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:A|${shop.location.latitude},${shop.location.longitude}`}
          src={`https://api.mapbox.com/styles/v1/mapbox/dark-v10/static/pin-l+d70f0f(${shop.location.longitude},${shop.location.latitude})/${shop.location.longitude},${shop.location.latitude},13,0/200x200@2x?access_token=${MAPBOX_TOKEN}`}
        />
      </div>

      {/* <p>{email}</p> */}
    </div>
  )
}

export default Card
