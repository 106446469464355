import React from "react"
import Card from "./Card"

const Cardlist = ({ shopArray }) => {
  return (

      <div className="flex flex-wrap justify-around w-100 center">
        {shopArray.map((shop, i) => {
          return <Card key={i} shop={shop}/>
        })}
      </div>
  )
}

export default Cardlist
